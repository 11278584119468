<template>
	<div  id="presentation" class="container" align="rigth">
		<h2 > Informations Personnelles</h2>
		<b-container class=" infoP ">
			<h3> Qui suis-je ? </h3>
			<p id="sep" ></p>
			<b-row>
				<b-col align="center" >
					<b-img id="image-personne" src="../assets/avatar.png" alt="fond personne" align="center"/>
				</b-col>
				<b-col class="infoR">
					<div >
						<b-row>
							<p><i class="fa fa-user fa-fw" aria-hidden="true"></i>  {{nom}} {{prenom}} </p>
						</b-row>
						<br>
						<b-row>
							<p><i class="fa fa-map-marker fa-fw" aria-hidden="true"></i>  {{codePostal}} {{ville}} </p> 
						</b-row>
						<br>
						<b-row>
							<p><i class="fa fa-info fa-fw" aria-hidden="true"></i>  {{age}} ans </p> 
						</b-row>
                    </div>
                    
                </b-col>
			</b-row>
		</b-container>
		<b-container class=" info " >
			<!-- Petite presentation  -->
			<h3> Quelques informations à mon sujet   </h3>
				<p id="sep" ></p>
				<!-- à souligner -->
			<b-row class="pad-personne">
				<b-col>
					<h3> Énergique </h3>
					<i class="fa fa-bolt fa-3x icons-personne" aria-hidden="true"></i>
					<br>
					<span class="container">
						J'aime être active, et rester longtemps sur des problèmes qu'il faut résoudreet ce jusqu'à ce que j'y arrive.
					</span>
				</b-col>
				<b-col>
					<h3> Automobile </h3>
					<i class="fa fa-car fa-3x icons-personne" aria-hidden="true"></i>
					<br>
					<span class="container">
						Je m'interresse à tout ce qui est automobile, tel que la Formule 1,
						ou encore wheeler dealers.	
					</span>
				</b-col>
				<b-col>
					<h3> Shopping </h3>
					<i class="fa fa-cart-plus fa-3x icons-personne" aria-hidden="true"></i>
					<br>
					<span class="container">
						J'aime beaucoup acheter des habits, des chaussures, c'est l'un de mes grands passe-temps.
					</span>
				</b-col>
				<b-col>
					<h3> Cinéma </h3>
					<i class="fa fa-film fa-3x icons-personne" aria-hidden="true"></i>
					<br>
					<span class="container">
						J'aime beaucoup tout ce qui est film, série, animé.
					</span>
				</b-col>
				
				<!--<b-card-group>
					<b-card title="Energique">
						<i class="fas fa-bolt"></i>
					<b-card-text>
						Je ne supporte pas de ne rien faire.<br>
								Et même si je ne reussi pas du premier coup, je continue jusqu'à y arriver :).
					</b-card-text>
					
					</b-card>

					<b-card title="Automobile" img-src="../assets/auto.jpg" img-alt="Image" img-top>
					<b-card-text>
						Je m'interresse à tout ce qui est automobile, tel que la Formule 1, ou encore wheeler dealers, mais pas France, la version originale, car ils font des voitures beaucoup plus intérresante.
					</b-card-text>
					</b-card>

					<b-card title="Cinéma" img-src="../assets/cinema.jpg" img-alt="Image" img-top>
					<b-card-text>
						J'aime beaucoup tout ce qui est film, série, animé.
					</b-card-text>
				
					</b-card>

					<b-card title="Shoppping" img-src="../assets/shopping.jpg" img-alt="Image" img-top>
					<b-card-text>
						J'aime beaucoup acheter des habits, des chaussures, c'est l'un de mes grands passe-temps.
					</b-card-text>
					
					</b-card>
				</b-card-group>-->
						
					
			</b-row>
		</b-container>
		
	</div>
</template>

<script>
export default {
	name:'Personne',
	data(){
		return{
			nom:' HACEN',
			prenom:'Imâne',
			age: 20,
			ville:' Avignon',
			codePostal:84000,
		}
	},
	methods:{
		getNom(){
			return this.nom;
		},
		getPrenom(){
			return this.prenom;
		},
		getAge(){
			return this.age;
		},
		getVille(){
			return this.ville;
		},
		getCodePostal(){
			return this.codePostal;
		},
		
	}
}
</script>
