<template>
  
    <div id="all">
    
      <Personne></Personne>
      <p class="between"></p>
      <Formations></Formations>
      <p class="between"></p>
      <Experiences></Experiences>
      <p class="between"></p>
      <Competences></Competences>
      <p class="between"></p>
      <Hobbies></Hobbies>
    </div>
  
</template>

<script>
// @ is an alias to /src
import Formations from '@/components/Formations.vue'
import Experiences from '@/components/Experiences.vue'
import Hobbies from '@/components/Hobbies.vue'
import Personne from '@/components/Personne.vue'
import Competences from '@/components/Competences.vue'


export default {
  name: 'FormationsView',
  components: {
    Formations,
    Experiences,
    Hobbies,
    Personne,
    Competences,
  }
}
</script>

